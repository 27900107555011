import axios from 'axios';
import {getHeaders} from "./common.js";


export default async function getHomepageData(key,islogedin) {
    let requestData = {
        "getKey": key || ""
    }

    let requestOptions = {
        method: 'GET',
        headers: getHeaders(),
        url: '/hotelsapi/homepage',
        params: requestData
    }

    try {
        let resp = await axios(requestOptions);
        if(!resp.data.status){
            throw resp.data;
        }
        return resp.data.data
    } catch (err) {
        return false;

    }
}