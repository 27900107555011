import CallAdobe from '../../actions/data';
import { differenceInCalendarDays } from "date-fns";



module.exports.cleverTapProductClick = async function (data, type, checkin, checkout, url, mopType) {
    try {
        window.clevertapData["Global"]["Category"] = (mopType === "redeem") ? "Redeem" : "Earn";
        window.clevertapData["Global"]["Channel"] = "Web";
        window.clevertapData["Global"]["CleverTap ID"] = window.clevertap?.getCleverTapID();

        window.clevertapData["Product Clicked"] = {
            "Action": "Search Result Click",
            "Destination Url": window.location.origin + url,
            "Hotel City": data.city,
            'City ID': data.destination_id?.toString().length <= 6 ? "" + data.destination_id : "",
            "Hotel Property": data.hotel_name,
            "Hotel Checking Date": new Date(checkin),
            "Hotel Check Out Date": new Date(checkout),
            "Hotel Traveller Adult": 2,
            "Hotel Traveller Child": 0,
            "Total Travellers": 2,
            "Hotel No Of Room": 1,
            "Hotel No Of Night": differenceInCalendarDays(new Date(checkout), new Date(checkin)),
            "Hotel Area": data.headline,
            "Hotel Country": data.country,
            "Hotel Star": data.star_rating,
            "Hotel Cancellation Policy": data.is_refundable === true ? "Free Cancellation" : "Non Refundable",
            "Hotel Customer Rating": data.customer_rating,
            "Hotel Urgency Text": data.available_rooms < 9 ? "Y" : "N",
            "Amount": data.price.price_inr,
            "Miles Redeemed": "",
            // "Miles Redeemed": data.earn_im.breakup.base.miles.miles,
            "Hotel Property ID": data.destination_id?.toString().length > 6 ? "" + data.destination_id : "",
            "Filter Used": "",
            "Sort Used": "",
            "Section Clicked": '' + type,
            "Hotel Partner ID": "",
            "No Of Days Before Booking": differenceInCalendarDays(new Date(checkin), new Date()),
            "Safe Travel": !!data?.is_covid ? 'Y' : 'N'
        };
        if (mopType != "redeem")
            delete window.clevertapData["Product Clicked"]["Miles Redeemed"];
        else delete window.clevertapData["Product Clicked"]["Amount"];

        CallAdobe.CallCT("Product Clicked");
    } catch (e) {
        console.log("ct err:", e);
    }
}



module.exports.clevertapOfferClickEvent = async function (element, id) {
    try {
        window.clevertapData["Global"]["Channel"] = "Web";
        window.clevertapData["Global"]["CleverTap ID"] = window.clevertap?.getCleverTapID();
        window.clevertapData["Offer"] = {
            'Action': "Offer Clicked",
            "Offer ID": element.offerUniqueId,
            "Offer Property": element.offerIdentifier,
            "Offer Name": element.offerTitle,
            "Offer Description": element.offerTitle,
            "Offer Category": "",
            "Offer Partner": "",
            "Offer Partner Code": "",
            "Offer Start Date": element.offerStartFrom,
            "Offer End Date": element.offerValidity,
            "Offer Position": id + 1,
            "Offer Section": "All Offers",
            "Offer Tab": "Hotels",
        };
        CallAdobe.CallCT("Offer");
    } catch (e) {
        console.log("ct err:", e);
    }
}

module.exports.cleverTapSearch = async function (Action, UrlString, city, country, destinationId, checkin, checkout, type) {


    try {
        window.clevertapData["Global"]["Channel"] = window.screen.availWidth <= 1023 ? "Mweb" : "Web";
        window.clevertapData["Global"]["CleverTap ID"] = window.clevertap?.getCleverTapID();
        window.clevertapData["Search"] = {
            "Action": Action,
            "Destination Url": window.location.origin + UrlString,
            "Hotel Search Term": city + "," + country,
            "Hotel Search Category": "City",
            "Hotel City": city,
            "City ID": destinationId?.toString().length <= 6 ? "" + destinationId : '',
            "Hotel Property": "",
            "Hotel Area": city,
            "Hotel Country": country,
            "Hotel Checking Date": new Date(checkin),
            "Hotel Check Out Date": new Date(checkout),
            "Hotel Traveller Adult": 2,
            "Hotel Traveller Child": 0,
            "Total Travellers": 2,
            "Hotel No Of Room ": 1,
            "Hotel No Of Night": parseInt(checkout.split('-')[2]) - parseInt(checkin.split('-')[2]),
            "Hotel Search Type": "",
            "Hotel Property ID": destinationId?.toString().length > 6 ? "" + destinationId : "",
            "Filter Used": "",
            "Sort Used": "",
            "Safe Travel": "",
            "No Of Days Before Booking": differenceInCalendarDays(new Date(checkin), new Date()),
            "Search Criteria": Action + ":" + city + ", " + country + ":" + new Date(checkin).toLocaleString().split(',')[0] + ":" + new Date(checkout).toLocaleString().split(',')[0] + ":2" + ":0" + ":1:" + differenceInCalendarDays(new Date(checkout), new Date(checkin)),
            "Search Modified Field": "",
            "Payment Type": type,

        };


        CallAdobe.CallCT("Search");
    } catch (e) {
        console.log("ct err:", e);
    }
}


