import { addDays, format } from "date-fns";

import config from '../../config/config_frontend.json'
import config_backend from '../../config/config_backend.json'

import getHomepageData from '../../actions/homepage'
import { utmUrlParam } from "../../services/commonUrlParams";
import { adobePropertyClick, AdobeErrorEvent, setUserInfo, AdobeSearchClick } from "./homePageAdobe"
import { cleverTapProductClick, cleverTapSearch } from "./homePageCleverTap"

module.exports.setInitialValue = async function (homepageData, loggedIn_Info) {
    await setUserInfo(loggedIn_Info)
    if (homepageData.length == 0) {
        let data = await getHomepageData("isBlocks,isBanner,isTiles,isOffer", false)
        if ((data && data["titles"] == null) || data["titles"] == undefined) return false
        return data
    } else {
        if ((homepageData && homepageData.titles == null) || homepageData.titles == undefined) {
            AdobeErrorEvent(loggedIn_Info)
            return false
        }
        return homepageData
    }
}



module.exports.HdpRedirect = function (data, type, loggedIn_Info, mopType) {
    // console.log(mopType);

    let checkin = format(addDays(new Date(), 7), 'yyyy-MM-dd');
    let checkout = format(addDays(new Date(), 8), 'yyyy-MM-dd');
    adobePropertyClick(data, type, checkin, checkout, loggedIn_Info)
    // Adobe end
    let utmUrl = utmUrlParam(window.location.href);
    let url = 'hoteldetails?region=' + encodeURIComponent(data.city) + '&name=' + encodeURIComponent(data.hotel_name) + '&country=' + encodeURIComponent(data.country) + '&checkin=' + checkin + '&checkout=' + checkout + '&mop=' + mopType + '&rooms=2a-0c-&type=location&roomtype=single&sortby=popularity&page=1&orderby=desc&vid=' + data.destination_id + '&days=1' + utmUrl;
    let results = {
        caption: 'Hotels in ' + data.city,
        path: config.Localhost + url,
        url: config.Localhost + url,
        isReactRoute: true
    }
    localStorage.setItem('breadCrumb', JSON.stringify(results))
    //clevertap start
    cleverTapProductClick(data, type, checkin, checkout, url, mopType)
    //clevertap end
    setTimeout(() => {
        window.open(config.Localhost + url, '_blank');
    }, 300)
}

export const addAdobeScript = async function () {
    if (!document.getElementById('adobeScript-id')) {
        const adobeScript = document.createElement('script');
        adobeScript.async = true;
        adobeScript.src = config_backend.HeaderFooterLink.AdobeUrl;
        adobeScript.id = "adobeScript-id"
        const body = document.body;
        body.appendChild(adobeScript);
    }
}

module.exports.handlePageReload = function () {
    window.location.reload();
}

export const discoveryCardClick = function (data, mop, e = false) {

    if (e) e.stopPropagation();

    let checkin = format(addDays(new Date(), 15), 'yyyy-MM-dd');
    let checkout = format(addDays(new Date(), 16), 'yyyy-MM-dd');
    AdobeSearchClick(checkin, checkout, "Discover Home Stay and Weekend Getaways", data.disc_country, data.disc_city)

    let utmUrl = utmUrlParam(window.location.href);
    // console.log("discover data",data);
    let UrlString = 'searchdetail?property=' + encodeURIComponent(data.disc_title) + '&region=' + encodeURIComponent(data.disc_city) + '&mop=' + mop + '&view=tiles&country=' + encodeURIComponent(data.disc_country) + '&destination_id=' + data.disc_dest_id + '&search_type=' + data.disc_search_type + '&checkin=' + checkin + '&checkout=' + checkout + '&disc=true' + '&rooms=2a-0c' + utmUrl;
    console.log("UrlString",UrlString)
    cleverTapSearch("Discover Home stay and Weekend Getaway", UrlString, data.disc_city, data.disc_country, data.disc_dest_id, checkin, checkout, "Accrual")
    window.location.href = config.Localhost + UrlString;
}

module.exports.topDestinationCardClick = function (data, mop) {
    let checkin = format(new Date(data.checkindate), 'yyyy-MM-dd');
    let checkout = format(new Date(data.checkoutdate), 'yyyy-MM-dd');
    AdobeSearchClick(checkin, checkout, "Top holiday destinations", data.country, data.city)

    let utmUrl = utmUrlParam(window.location.href);

    let urlString = 'searchdetail?&region=' + encodeURIComponent(data.city) + '&mop=' + mop + '&view=tiles&country=' + encodeURIComponent(data.country) + '&destination_id=' + data.destination_id + '&search_type=' + data.search_type + '&checkin=' + checkin + '&checkout=' + checkout + '&rooms=2a-0c' + utmUrl;
    cleverTapSearch("Top holiday destinations", urlString, data.city, data.country, data.destination_id, checkin, checkout, "Accrual")
    setTimeout(() => {
        window.location.href = config.Localhost + urlString;
    }, 300)
}

module.exports.apartmentCardClick = function (data, mop) {
    let expCheckin = format(addDays(new Date(), 1), 'yyyy-MM-dd');
    let apiCheckin = format(new Date(data.checkindate), 'yyyy-MM-dd');
    let checkin = (apiCheckin < expCheckin) ? expCheckin : apiCheckin;
    let expCheckout = format(addDays(new Date(), 2), 'yyyy-MM-dd');
    let apiCheckout = format(new Date(data.checkoutdate), 'yyyy-MM-dd');
    let checkout = (apiCheckout < expCheckout) ? expCheckout : apiCheckout;
    AdobeSearchClick(checkin, checkout, "Apartments and More", data.disc_country, data.city)

    let utmUrl = utmUrlParam(window.location.href);

    let urlString = 'searchdetail?property=' + encodeURIComponent(data.title) + '&region=' + encodeURIComponent(data.city) + '&mop=' + mop + '&view=tiles&country=' + encodeURIComponent(data.country) + '&destination_id=' + data.destination_id + '&search_type=' + data.search_type + '&checkin=' + checkin + '&checkout=' + checkout + '&rooms=2a-0c&filterArray='
        // + (data.propertytype ? data.propertytype + 'propertytype':"") 
        + data.filterarray + utmUrl

    cleverTapSearch("Apartments and More", urlString, data.city, data.country, data.destination_id, checkin, checkout, "Accrual")

    setTimeout(() => {
        window.location.href = config.Localhost + urlString;
    }, 300)
}

export function exploreRedirect(e, data, mop) {
    e.stopPropagation();
    // let utmUrl = utmUrlParam(window.location.href);
    let UrlString = `explore${data.url}?mop=${mop}`
    window.location.href = config.Localhost + UrlString;
}