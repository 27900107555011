import { Adobe } from '../../actions/AdobeLayerNew';
import dateFormat from 'dateformat';


let userInfo = {}
let pageName = "hotelsnew-home";
let subCategory1 = "home";
let milesCategory = "None";

module.exports.setUserInfo = function (loggedIn_Info) {
    userInfo.JpNumber = loggedIn_Info?.isLoggedIn ? loggedIn_Info?.member_number : '';
    userInfo.tier = loggedIn_Info?.isLoggedIn ? loggedIn_Info?.tier : '';
    userInfo.pointsBalance = loggedIn_Info?.isLoggedIn ? loggedIn_Info?.points_balance : '';
    userInfo.loginStatus = loggedIn_Info?.isLoggedIn ? "LOGGED-IN" : "ANONYMOUS";
}


module.exports.adobePropertyClick = async function (data, type, checkin, checkout) {
    let date_difference = datediff(checkin, checkout)

    try {

        let search_info = {
            propertyName: `${data.hotel_name}, ${data.city}, ${data.country}`,
            searchType: 'suggested',
            sectionClicked: type,
            searchResultType: type,
            destination: `${data.city}, ${data.country}`,
            destinationCategory: "hotel name",
            bookingDate: `${dateFormat(checkin, "dd-mmm-yyyy")}|${dateFormat(checkout, "dd-mmm-yyyy")}`,
            noofNights: date_difference,
            guests: "2:0",
            rooms: "1",
            searchTerm: "",
            totalTravellers: "2",
        }
        let hotelId = data?.destination_id || ""
        // Adobe.propertyClick(pageName,subCategory1,milesCategory,filterUsed,searchInfo,sortValue,hotelId,paymentType)
        Adobe.propertyClick(pageName, subCategory1, milesCategory, userInfo, "", search_info, "", hotelId, "Accrual")
    } catch (err) {
        console.log(err);
    }
}

const datediff = (startdate, enddate) => {
    const date1 = new Date(startdate);
    const date2 = new Date(enddate);
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

module.exports.AdobeErrorEvent = async function () {
    try {
        // Adobe.errorTracking(pageName,subCategory1,milesCategory,JPNumber,tier,pointsBalance,loginStatus,errorDescription,errorCode)
        Adobe.errorTracking(pageName, subCategory1, milesCategory, userInfo, "Oop's Something Went Wrong", "404");
    } catch {
        console.log("error")
    }
}

module.exports.AdobePageLoad = async function () {
    try {
        Adobe.pageLoad(pageName, subCategory1, milesCategory, userInfo)
    } catch (err) {
        console.log(err);
    }
}


module.exports.AdobeofferClickEvent = async function (element, id) {
    try {
        let offerInfo = {
            tabName: element?.offerTagNewlyAdded === true ? 'newly added' : 'all offers',
            offerName: element?.offerTitle ? element?.offerTitle : '',
            offersDisplayed: element?.offerTitle ? element?.offerTitle : '',
            offerExpire: element?.offerValidity ? dateToDMY(element?.offerValidity) : '',
            offerPosition: id ? id + 1 : '',
        };
        // Adobe.offerClickEvent(pageName,subCategory1,milesCategory,userInfo,offerInfo)
        Adobe.offerClickEvent(pageName, subCategory1, milesCategory, userInfo, offerInfo);
    } catch (err) {
        console.log(err);
    }
}

function dateToDMY(dt) {
    let date = new Date(dt);
    var d = date.getDate();
    var m = date.getMonth() + 1; //Month from 0 to 11
    var y = date.getFullYear();
    return '' + (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
}



module.exports.AdobeSearchClick = async function (checkin, checkout, searchResultType, country, city) {
    try {
        let searchInfo = {
            searchResultType: searchResultType,
            destination: city + ', ' + country,
            destinationCategory: "Area",
            propertyName: "",
            bookingDate: `${dateFormat(checkin, "dd-mmm-yyyy")}|${dateFormat(checkout, "dd-mmm-yyyy")}`,
            noofNights: noofNights(new Date(checkin), new Date(checkout)),
            guests: `2:0`,
            rooms: `1`,
            searchTerm: "",
            searchType: "",
            totalTravellers: `2`,
        }
        // Adobe.InterbookSearchClick((pageName,subCategory1,milesCategory,userInfo,hotelId,searchInfo)
        Adobe.InterbookSearchClick(pageName, subCategory1, milesCategory, userInfo, "", searchInfo, "Star Rating", "");
    } catch {
        console.log("error")
    }
}

const noofNights = (datestart, dateend) => {
    const days = dateend.getTime() - datestart.getTime();
    return days / (1000 * 60 * 60 * 24)
}


module.exports.Clickevent = async function (type) {

    // Adobe.clickEvent(eventName,pageName,subCategory1,subCategory2,subCategory3,milesCategory,userInfo) 
    Adobe.clickEvent(type, pageName, subCategory1, "", "", milesCategory, userInfo,)
}